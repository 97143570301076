<template>
    <r-e-dialog :title="title" class="layer-contract-detail" :visible.sync="dialogVisible" show-footer top="10vh"
                width="550px" @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="100px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="账单信息">
                    <el-input v-model="formPublish.name" style="width: 365px;" placeholder="请输入充值金额" disabled/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="账单金额">
                    <el-input-number v-model="billAmount" style="width: 365px;" :precision="2" :step="1" disabled/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="已付金额">
                    <el-input-number v-model="paidAmount" style="width: 365px;" :precision="2" :step="1" disabled/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="未付金额">
                    <el-input-number v-model="noPaidAmount" style="width: 365px;" :precision="2" :step="1" disabled/>
                </el-form-item>
            </div>
            <!-- 2024/05/09 张晓瑜去除余额抵扣 -->
            <!-- <div class="flex">
                <el-form-item label="余额抵扣">
                    <el-input-number v-model="formPublish.availableBalance" style="width: 365px;" :precision="2" :step="1" disabled/>
                </el-form-item>
            </div> -->
            <div class="flex">
                <el-form-item label="实付金额">
                    <el-input-number v-model="formPublish.amount" style="width: 365px;" :precision="2" :step="1" disabled/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="付款日期" prop="cancelTime">
                    <el-date-picker v-model="formPublish.cancelTime" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 365px;" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期"/>
                </el-form-item>
            </div>

            <div class="flex">
                <el-form-item label="付款方式" prop="paymentMethod">
                    <el-select v-model="formPublish.paymentMethod" style="width: 365px;" placeholder="请选择付款方式">
                        <el-option v-for="(item,index) in paymentTypeList" :key="index" :label="item.codeName"
                                   :value="item.code"/>
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="图片" prop="imgs">
                    <upload-picture-card :uuidList="imgList" :limit="24" @on-success="handleSuccess" @on-remove="handleRemove" />
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import {deposit, paydeposit,getDeposit} from "@/api/house-resource";
    import {MessageSuccess} from "@custom/message";
    import {ParseFloatFormat, ParseIntFormat} from "@custom/index";
    import {getAmountData} from "@/api/bill";
    import {getBillDeductionAPI} from "@/api/bill";
    export default {
        name: "layer-write-off-bill",
        data() {
            return {
                dialogVisible: false,
                formPublish: {
                    uuidOrder: null,
                    transactionCode: null,
                    amount: null,
                    type: null,
                    comment: null,
                    uuid: null,
                    name: null,
                    paymentMethod: null,
                    cancelTime: null,
                    imgs: null,
                    availableBalance: null,
                },
                paymentTypeList: [],
                rules: {
                    paymentMethod: [{required: true, message: '请选择付款方式', trigger: 'change'}],
                    cancelTime: [{required: true, message: '请选择付款日期', trigger: 'change'}],
                    imgs: [{required: true, message: '请上传核销图片', trigger: 'blur'}],
                },
                title: '账单核销',
                loadingOptions: {
                    lock: true,
                    text: "Loading...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
                imgList: [],
                billAmount: null,
                amountData: null,
                paidAmount: null,
                noPaidAmount: null,
            };
        },
        async created() {
            // 获取用户uuid
            this.paymentTypeList = await this.$store.dispatch("app/getDictionaryByCodeActions", "500100");
            this.paymentTypeList = this.paymentTypeList.filter(item => item.code !== 500101 && item.code !== 500102); //&& item.code !== 500106 && item.code !== 500107
        },
        components: {
            uploadPictureCard: () => import("@/components/Upload/upload-picture-card"),
        },
        methods: {
           async openDialog(data) {
                console.log('data',data);
                let that = this;
                let {uuid: uuidOrder, leasorUuid: uuid, amount, name, type} = data;
                console.log('uuid',uuid);
                that.billAmount = ParseFloatFormat(amount);
                type = parseInt(type);
                let comment = "pc";
                let transactionCode = null;
                const oldFormPublish = that.formPublish;
                that.formPublish = {...oldFormPublish, uuidOrder, transactionCode, type, comment, uuid, name};
                that.getDeposit(uuid, type, amount, uuidOrder);
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                await getBillDeductionAPI({uuid:uuidOrder}).then(res => {
                    let { info:{amountPaid,unpaidAmount} } = res;
                    that.paidAmount = amountPaid; // 已付金额
                    that.noPaidAmount = unpaidAmount; // 未付金额
                    that.formPublish.amount = unpaidAmount; // 实付金额
                }).finally(() => loading.close());
                that.dialogVisible = true;
            },
            clickSubmit() {
                let that = this;
                this.$refs["formPublish"].validate((valid) =>{ if (valid) that.he(this.formPublish)});
            },
            // async getAmount(uuid) {
            //     const res = await getAmountData({uuid});
            //     const {info} = res;
            //     const {paidAmount,noPaidAmount} = info;
            //     this.amountData = info;
            //     this.paidAmount = ParseFloatFormat(paidAmount);
            //     this.noPaidAmount = ParseFloatFormat(noPaidAmount);
            // },
            he(data) {
                let that = this;
                this.$confirm('此操作将永久核销此账单, 是否继续?', '提示', {type: 'warning'}).then(() => {
                    let {uuid, uuidOrder, amount, transactionCode, type, comment, paymentMethod, cancelTime, imgs, availableBalance} = data;
                    transactionCode = Math.random();
                    type = parseInt(type);
                    amount = ParseIntFormat(amount);
                    availableBalance = ParseIntFormat(availableBalance);
                    cancelTime = (new Date(cancelTime)).getTime();
                    let paramData = {uuidOrder, transactionCode, type, comment, paymentMethod, cancelTime, imgs};
                    if (type === 500512) paramData = {...paramData, depo_amount: amount};
                    else if (type === 500504) paramData = {...paramData, rental_amount: amount, useRentalBalanceAmount: availableBalance};
                    else paramData = {...paramData, expense_amount: amount, useExpenseBalanceAmount: availableBalance};
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    deposit(uuid, paramData).then(res => {
                        if (type === 500512) {
                            paydeposit(uuidOrder).then(resp => {
                                setTimeout(() => {
                                    MessageSuccess('核销成功');
                                    that.clickCancel();
                                    loading.close();
                                }, 3000);
                            }).catch(err => loading.close());
                        } else {
                            setTimeout(() => {
                                MessageSuccess('核销成功');
                                that.clickCancel();
                                loading.close();
                            },3000);
                        }
                    }).catch(err => loading.close());
                }).catch(() => {});
            },
            async clickCancel() {
                this.formPublish = {
                    uuidOrder: null,
                    transactionCode: null,
                    amount: null,
                    type: null,
                    comment: null,
                    uuid: null,
                    name: null,
                    paymentMethod: null,
                    cancelTime: null,
                    imgs: null,
                    availableBalance: null,
                };
                this.imgList = [];
                this.noPaidAmount = null;
                this.paidAmount = null;
                await this.$emit('clickCancel');
                this.dialogVisible = false;
            },

            handleSuccess({fileList}) {
                this.imgList = fileList.map(item => {
                    let {name, response} = item;
                    let resOk = response && typeof (response) !== undefined;
                    if (resOk) {
                        let {returnObject: {info: {uuid}}} = response;
                        return uuid;
                    } else return name;
                });
                this.formPublish.imgs = this.imgList.join(";");
                this.$refs["formPublish"].validateField("imgs");
            },

            handleRemove({fileList}) {
                this.imgList = fileList.map(item => {
                    let {name, response} = item;
                    let resOk = response && typeof (response) !== undefined;
                    if (resOk) {
                        let {returnObject: {info: {uuid}}} = response;
                        return uuid;
                    } else return name;
                });
                this.formPublish.imgs = this.imgList.join(";");
                this.$refs["formPublish"].validateField("imgs");
            },

            async getDeposit(uuid, type, amount, uuidOrder) {
                const oldFormPublish = this.formPublish;
                amount = ParseFloatFormat(amount);
                const paramData = {uuid};
                const loadingOptions = this.loadingOptions;
                const loading = this.$loading({...loadingOptions});
                const res = await getDeposit(paramData).finally(() => loading.close());
                // 2024/05/08 张晓瑜去掉抵扣提示
                // await this.getAmount(uuidOrder);
                // if (res) {
                //     const {info: {expense_balance, rental_balance}} = res;
                //     let noPaidAmount = this.noPaidAmount;
                //     if (type === 500512) this.formPublish = {...oldFormPublish, availableBalance: 0, amount: noPaidAmount};
                //     else if(type === 500504) {
                //         let availableBalance  = ParseFloatFormat(rental_balance);
                //         if (availableBalance > 0) {
                //             this.$confirm('您的房租余额剩余' + availableBalance + ', 是否使用抵扣?', '提示', {type: 'warning'}).then(() => {
                //                 if (availableBalance > noPaidAmount) this.formPublish = {...oldFormPublish, availableBalance: noPaidAmount, amount: 0};
                //                 else this.formPublish = {...oldFormPublish, availableBalance, amount: noPaidAmount - availableBalance};
                //             }).catch(() => this.formPublish = {...oldFormPublish, availableBalance: 0, amount: noPaidAmount});
                //         } else this.formPublish = {...oldFormPublish, availableBalance: 0, amount: noPaidAmount};
                //     } else {
                //         let availableBalance  = ParseFloatFormat(expense_balance);
                //         if (availableBalance > 0) {
                //             this.$confirm('您的杂费余额剩余' + availableBalance + ', 是否使用抵扣?', '提示', {type: 'warning'}).then(() => {
                //                 if (availableBalance > noPaidAmount) this.formPublish = {...oldFormPublish, availableBalance: noPaidAmount, amount: 0};
                //                 else this.formPublish = {...oldFormPublish, availableBalance, amount: noPaidAmount - availableBalance};
                //             }).catch(() => this.formPublish = {...oldFormPublish, availableBalance: 0, amount: noPaidAmount});
                //         } else this.formPublish = {...oldFormPublish, availableBalance: 0, amount: noPaidAmount};
                //     }
                // }
            },
        }
    }
</script>

<style scoped>

</style>